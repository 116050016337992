import React from 'react'
export default class Sidebar extends React.Component{
    constructor(props){
        super(props)
    }
    render(){
        return (
            <div className='sidebar-main' style={{backgroundColor:this.props.theme_color}} id='side-bar'>
                   <div className="row">
                   <div className="col-12 mb-2" >
                        <p className="j_stickyhead" style={{color:'#fff'}}>
                            <a href="#" className="scroll-links j_anchor sidebar-links" style={{color:'#fff'}} onClick={()=>this.props.ShowSideBar()}>Home</a></p>
                    </div>
                    <div className="col-12 mb-2" >
                        <p className="j_stickyhead" style={{color:'#fff'}}>
                            <a href="#overview" className="scroll-links j_anchor sidebar-links" style={{color:'#fff'}} onClick={()=>this.props.ShowSideBar()}>Overview</a></p>
                    </div>
                    <div className="col-12  mb-2">
                        <p className="j_stickyhead">
                            <a href="#floor-plan" className="scroll-links j_anchor sidebar-links" style={{color:'#fff'}} onClick={()=>this.props.ShowSideBar()}>Floor Plan</a></p>
                    </div>
                    <div className="col-12  mb-2">
                        <p className="j_stickyhead">
                            <a href="#amenties" className="scroll-links j_anchor sidebar-links" style={{color:'#fff'}} onClick={()=>this.props.ShowSideBar()}>Amenities</a></p>
                    </div>
                    <div className="col-12  mb-2">
                        <p className="j_stickyhead">
                            <a href="#map_view" className="scroll-links j_anchor sidebar-links" style={{color:'#fff'}} onClick={()=>this.props.ShowSideBar()}>Locality</a>
                        </p>
                    </div>
                    <div className="col-12  mb-2">
                        <p className="j_stickyhead">
                            <a href="#walkthrough" className="scroll-links j_anchor sidebar-links" style={{color:'#fff'}} onClick={()=>this.props.ShowSideBar()}>3D WalkThrough</a></p>
                    </div>
                    <div className="col-12  mb-2">
                        <p className="j_stickyhead">
                            <a href="#location" className="scroll-links j_anchor sidebar-links" style={{color:'#fff'}}  onClick={()=>this.props.ShowBrochureModal()}>Download brochure</a></p>
                    </div>
                   
                </div>
                <div className='close-div-sidebar'>
                    <button style={{background:'transparent',border:0}} onClick={()=>this.props.ShowSideBar()}>
                        <img src={require('../../assets/images/close_icon.svg')} style={{padding:'16px',fontSize:'12px'}}></img>
                    </button>
                </div>

            </div>
        )
    }
}