import React from 'react'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import '../../assets/css/style.css'
export default class PriceBreakUpModal extends React.Component{
    constructor(props){
        super(props)
        // alert(1)
    }
    render(){
        return(
            <Modal open={this.props.open} onClose={this.props.HidePriceBreakUpModal} center
            classNames={{
                // overlay: 'customOverlay',
                modal: 'customModal',
              }}>
        
        <div className="modal-header brochure-modal-header">
                                <h5 className="modal-title brochure-modal-title" id="exampleModalLongTitle">Send me plan details</h5>
                                {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button> */}
                            </div>
                            <div className="modal-body brochure-modal-body">
                                <div className='row'>
                                    <div className="col-sm-12 col-md-12 col-lg-12">
                                        <form style={{ marginTop: -38 }}>
                                            <div className="form-row align-items-center">
                                                <div className="col-sm-12 col-md-12 col-lg-12">
                                                    <label className="sr-only" htmlFor="txtFloorPlanUserName" className='form-label-contact'>Name </label>
                                                    <input type="text" className="form-control j_forminputf" id="txtFloorPlanUserName" name="txtFloorPlanUserName" onChange={this.props.HandleUserInputsFloorPlan} placeholder="Enter name" value={this.props.mystate.txtFloorPlanUserName} />
                                                </div>

                                                <div className="col-sm-12 col-md-12 col-lg-12">
                                                    <label className="sr-only" htmlFor="txtFloorPlanUserEmail" className='form-label-contact'>Email ID</label>
                                                    <input sty type="email" className="form-control j_forminputf " id="txtFloorPlanUserEmail" name="txtFloorPlanUserEmail" onChange={this.props.HandleUserInputsFloorPlan} placeholder="Enter email ID" value={this.props.mystate.txtFloorPlanUserEmail} />
                                                </div>

                                                <div className="col-sm-12 col-md-12 col-lg-12" style={{marginBottom:20}}>
                                                    <label className="sr-only" htmlFor="txtFloorPlanMobile" className='form-label-contact'>Mobile number</label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text bg-light" style={{ borderRadius: 0 }}>+91</div>
                                                        </div>
                                                        <input type="text" className="form-control j_forminputf" maxLength="10" id="txtFloorPlanMobile" name="txtFloorPlanMobile" onChange={this.props.HandleUserInputsFloorPlan} value={this.props.mystate.txtFloorPlanMobile} placeholder="Enter mobile number" />
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 col-md-12 col-lg-12">
                                                    <label className="sr-only" htmlFor="txtFloorPlanComment" className='form-label-contact'>Comment </label>
                                                    <input type="text" className="form-control j_forminputf" id="txtFloorPlanComment" name="txtFloorPlanComment" onChange={this.props.HandleUserInputsFloorPlan} placeholder="Comment" value={this.props.mystate.txtFloorPlanComment} />
                                                </div>
                                                {this.props.validationFloorFormMessage!==''?
                                    <div className="col-sm-12 col-md-12 col-lg-12">
                                        <div className="errorMessage">{this.props.validationFloorFormMessage}</div>
                                    </div>:null}
                                                <div className="col-sm-12 col-md-12 col-lg-12" style={{ marginTop: '24px' }}>
                                                    <button type="button" className="j_btn" style={{backgroundColor:this.props.theme_color}} onClick={this.props.SendSitePlanMail}>Send Now</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                           
                          
      </Modal>
           
        )
    }
}