import React, { Component } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import '../../assets/css/style.css'
export default class MobModal extends Component {
    render() {
        const current_url=`https://wa.me//send?phone=918047193000&text=I'm Interested In ${this.props.prop_name} ,${this.props.prop_location}. Please Share Details.`
        return (

            
            <Modal open={this.props.open} onClose={this.props.HideMobModal} center
            classNames={{
                // overlay: 'customOverlay',
                modal: 'customModal',
              }}>
                 
                        <form className="border-2 border-rounded" style={{ padding: '10px 23px 20px' }} >
                            <div className="form-row align-items-center">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <label className="sr-only" className='form-label-contact' htmlFor="txtModalFullName">Name </label>
                                    <input type="text" className="form-control j_forminputf" id="txtModalFullName" name="txtModalFullName" onChange={this.props.HandleChangeModal} placeholder="Enter name" />
                                </div>

                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <label className="sr-only" className='form-label-contact' htmlFor="txtModalUserEmail">Email ID</label>
                                    <input type="email" className="form-control j_forminputf " id="txtModalUserEmail" name="txtModalUserEmail" onChange={this.props.HandleChangeModal} placeholder="Enter email ID" />
                                </div>

                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <label className="sr-only" className='form-label-contact' htmlFor="txtModalUserContact">Mobile no</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text bg-light" style={{ borderRadius: 0 }}>+91</div>
                                        </div>
                                        <input type="text" className="form-control j_forminputf " id="txtModalUserContact" name="txtModalUserContact" onChange={this.props.HandleChangeModal} maxLength="10" placeholder="Enter mobile number" />
                                    </div>
                                </div>
                                
                                
                                     {this.props.validationMobModalFormMessage!==''?
                                    <div className="col-sm-12 col-md-12 col-lg-12">
                                        <div className="errorMessage">{this.props.validationMobModalFormMessage}</div>
                                    </div>:null}
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-check mb-2 mt-3">
                                        <input className="form-check-input" type="checkbox" id="autoSizingCheck" style={{ opacity: 0.6 }} />
                                        <label className="form-check-label" htmlFor="autoSizingCheck" style={{ verticalAlign: 'super', opacity: 0.6 }}>
                                            Send an update on
                                    </label>
                                        <img src={require('../../assets/images/whatsapp.svg')} alt="img upload" style={{ height: 20, marginTop: -15,marginLeft:6 }} />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-6 col-md-12 col-lg-12" style={{ paddingBottom: 3 }}>
                                    <button type="button" style={{backgroundColor:this.props.theme_color}} className="j_btn"   onClick={this.props.SendModalMail}>Enquire</button>
                                </div>
                            </div>
                        </form>
                      
                   
                
                                    
                                <button   style={{backgroundColor:this.props.theme_color,}} className='btn-container ' onClick={()=>this.props.HideMobModal()}>
                                <span aria-hidden="true" style={{color:'#fff',fontSize:40,paddingBottom:5}}>&times;</span>
                                </button>
                                </Modal>
   
        )
    }
}