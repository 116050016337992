import React, { Component, Suspense } from 'react';
import Sidebar from '../common/sidebar';
// import Header from '../common/header';

import 'lightbox-react/style.css';
import Lightbox from 'lightbox-react';
// import ReactCC from "react-component-caching"
import { colorArray } from './color'
// import MoreInfo from './moreinfo';
// import MapView from './mapview'

// import 'bootstrap'
// import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/style.css';
import BrochureModal from './brochure-modal'
import PriceBreakUpModal from './plan-breakup-modal'
import MobFormModal from './modal';
import axios from 'axios';

const Header = React.lazy(() => import('../common/header'));
const ContactModal = React.lazy(() => import('./contact-modal'));
const SliderImgCtrl = React.lazy(() => import('./slider'));
const SliderBottom = React.lazy(() => import('./slider-bottom'));
const FormCtrl = React.lazy(() => import('./form'));
const OverviewCtrl = React.lazy(() => import('./overview'));
const SitePlan = React.lazy(() => import('./siteplan'));
const AmentiesCtrl = React.lazy(() => import('./amenties'));
const VideoCtrl = React.lazy(() => import('./video'));
// const MobFormModal = React.lazy(() => import('./modal'));
// const BrochureModal = React.lazy(() => import('./brochure-modal'));

const VideoModal = React.lazy(() => import('./video-modal'));
const MapView = React.lazy(() => import('./mapview'));
const MoreInfo = React.lazy(() => import('./moreinfo'));

let paths = window.location.protocol + '//' + window.location.host + window.location.pathname;

const rawFile = new XMLHttpRequest();
let file = '';

if (paths.includes('http://localhost:300') || paths.includes('https://lnt-realty.in.net')) {
// if (paths.includes('http://localhost:300')) {
    file = require('./Data.xml')
}
else {
    //alert(window.location.protocol + '//' + window.location.host + window.location.pathname);
    // file = require('./Data.xml');
    file = paths + 'Data.xml';
}

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.images = [];
        this.server_path = window.location.protocol + '//' + window.location.host + window.location.pathname;
        this.db_server_path = 'https://www.pinclick.com/'
        let theme_color = '#fa4923', form_title = '', builder_name = '', builder_description = '', builder_contact = '', project_status = '', form_apartment = '', form_location = '', price = '', prop_price_range = '', prop_video_url = '', meta_title = '', meta_keyword = '', meta_description = '';
        let title = '', logo = '', banner_image = '', pin_logo = 'logo1.png_1561465502760', prop_status = '', master_img = '', site_img = '', brochure = '', rera = '', banner_img_list = [], about_prop = '', success_url = '';
        let amenities_arr = [], final_amenities_arr = [], pricing_floor_plans_img_arr = [], location_map_img = '';
        let static_amenities = ["24x7 Security.svg", "CCTV.svg", "Landscape Garden.svg", "Club House.svg", "Gym.svg", "Power Backup.svg", "Swimming Pool.svg"]

        rawFile.onreadystatechange = () => {
            if (rawFile.readyState === 4 && (rawFile.status === 200 || rawFile.status === 0)) {
                const parser = new DOMParser();

                const xml = parser.parseFromString(rawFile.response, 'text/xml');


                let uri = '';

                if (xml.getElementsByTagName('PROPID')[0] !== undefined) {
                    let prop_id = xml.getElementsByTagName('PROPID')[0].childNodes[0].nodeValue;
                    // t("prop_id " + prop_id)aler
                    uri = 'admin/property/row/json/' + prop_id
                    // alert("prop_id uri " + uri)
                }
                else if (xml.getElementsByTagName('SEOURL')[0]) {
                    let seo_url = xml.getElementsByTagName('SEOURL')[0].childNodes[0].nodeValue;
                    uri = 'admin/property/row/json/seourl/' + seo_url
                    // alert("seo_url " + uri)
                }

                title = xml.getElementsByTagName('TITLE')[0].childNodes[0].nodeValue;
                logo = xml.getElementsByTagName('LOGO')[0].childNodes[0].nodeValue;
                pin_logo = xml.getElementsByTagName('PINLOGO')[0].childNodes[0].nodeValue;
                banner_image = xml.getElementsByTagName('BANNERIMAGE')[0].childNodes[0].nodeValue;
                success_url = xml.getElementsByTagName('SUCCESSURL')[0].childNodes[0].nodeValue;
                about_prop = xml.getElementsByTagName('ABOUT')[0].childNodes[0].nodeValue;
                master_img = xml.getElementsByTagName('MASTERPLAN')[0].childNodes[0].nodeValue;
                this.images.push({ 'type': 'master_image', 'list': [{ 'img': this.db_server_path + 'images/uploads/property/' + master_img }] });
                site_img = xml.getElementsByTagName('SITEPLAN')[0].childNodes[0].nodeValue;
                prop_video_url = xml.getElementsByTagName('VIDEOURL')[0].childNodes[0].nodeValue;
                location_map_img = xml.getElementsByTagName('LOCATIONMAP')[0].getAttribute('link');

                let meta = xml.getElementsByTagName('META');
                // let meta_title = '', meta_keyword = '', meta_description = '';
                for (let i = 0; i < meta.length; i++) {
                    var objectElem = meta[i];
                    meta_title = objectElem.getElementsByTagName("MetaTitle")[0].getAttribute('title');
                    meta_description = (objectElem.getElementsByTagName("MetaDescription")[0].getAttribute('description'));
                    meta_keyword = (objectElem.getElementsByTagName("MetaKeyword")[0].getAttribute('Keyword'));
                    //par = '<img class="item" href="#" ondblclick="confirm()" src="'+ a +'" title="' +b+ '-'+ c+ '" />';
                    // alert("=="+meta_title + "--" + meta_description + "--" + meta_keyword);
                }

                let form = xml.getElementsByTagName('FORM');
                // let form_title = '', form_location = '', price = '', prop_price_range = '', form_apartment = '';
                for (let i = 0; i < form.length; i++) {
                    var objectElem = form[i];
                    form_title = objectElem.getElementsByTagName("FORMTITLE")[0].textContent;
                    form_location = objectElem.getElementsByTagName("FORMLOCATION")[0].textContent;
                    price = objectElem.getElementsByTagName("PRICE")[0].textContent;
                    prop_price_range = objectElem.getElementsByTagName("PRICERANGE")[0].textContent;
                    form_apartment = objectElem.getElementsByTagName("APARTMENT")[0].textContent;
                }

                let builder = xml.getElementsByTagName('BUILDER');
                // let builder_name = '', builder_description = '', builder_contact = '', rera = '', project_status = '';
                for (let i = 0; i < builder.length; i++) {
                    var objectElem = builder[i];
                    builder_name = objectElem.getElementsByTagName("BUILDERNAME")[0].textContent;
                    builder_description = objectElem.getElementsByTagName("BUILDERDESCRIPTION")[0].textContent;
                    builder_contact = objectElem.getElementsByTagName("BUILDERCONTACT")[0].textContent;
                    rera = objectElem.getElementsByTagName("RERASTATUS")[0].textContent;
                    project_status = objectElem.getElementsByTagName("PROJECTSTATUS")[0].textContent;
                }

                // let floor_plans = xml.getElementsByTagName('FLOORPLANS');
                // // let builder_name = '', builder_description = '', builder_contact = '', rera = '', project_status = '';
                // for (let i = 0; i < floor_plans.length; i++) {
                //     var objectElem = floor_plans[i];
                //     builder_name = objectElem.getElementsByTagName("BUILDERNAME")[0].textContent;
                //     builder_description = objectElem.getElementsByTagName("BUILDERDESCRIPTION")[0].textContent;
                //     builder_contact = objectElem.getElementsByTagName("BUILDERCONTACT")[0].textContent;
                //     rera = objectElem.getElementsByTagName("RERASTATUS")[0].textContent;
                //     project_status = objectElem.getElementsByTagName("PROJECTSTATUS")[0].textContent;
                // }

                let floor_plans = xml.documentElement.getElementsByTagName('FLOORPLANS');
                let projects_items = floor_plans[0].getElementsByTagName('ITEM');
                for (let i = 0; i < projects_items.length; i++) {
                    if (projects_items[i].getElementsByTagName("BHK")[0].textContent !== '') {
                        pricing_floor_plans_img_arr.push({ 'floor_type_img': projects_items[i].getElementsByTagName("IMAGE")[0].textContent, 'floor_type': projects_items[i].getElementsByTagName("BHK")[0].textContent });
                        let typeIndex = this.images.findIndex(item => item.type === "floor_image");
                        if (typeIndex == -1) {
                            this.images.push({ 'type': 'floor_image', 'list': [{ 'img': this.db_server_path + 'images/uploads/property/' + projects_items[i].getElementsByTagName("IMAGE")[0].textContent }] });
                        }
                        else {
                            this.images[typeIndex].list.push({ 'img': this.db_server_path + 'images/uploads/property/' + projects_items[i].getElementsByTagName("IMAGE")[0].textContent })
                        }
                    }
                }

                let amenities = xml.documentElement.getElementsByTagName('AMENITIES');
                let ament = amenities[0].getElementsByTagName('IMAGES');
                let ament_img = ament[0].getElementsByTagName('AmenitiesImageUrl');
                for (let i = 0; i < ament_img.length; i++) {
                    // alert(ament_img[i].textContent);
                    amenities_arr.push({ 'spec_name': ament_img[i].textContent })
                }

                amenities_arr.map(ele => {
                    static_amenities.forEach(element => {
                        if (element.includes(ele.spec_name))
                            final_amenities_arr.push({ 'name': ele.spec_name, 'image': element })
                    })
                });
                banner_img_list.push(banner_image)
                let slide = xml.documentElement.getElementsByTagName('MAINSLIDER');
                let slide_img = slide[0].getElementsByTagName('IMAGES');
                for (let i = 0; i < slide_img.length; i++) {
                    // alert(slide_img[i].textContent);
                    banner_img_list.push(slide_img[i].textContent);
                }
            }
        }
        rawFile.open('GET', file, false);
        rawFile.send();

        colorArray.map(item => {
            if (item.builder_name.includes(builder_name) || builder_name.includes(item.builder_name)) {

                theme_color = item.color
            }
            // else{
            //     theme_color = '#fa4923'
            // }
        })

        this.state = {
            logo: logo,
            pin_logo: pin_logo,
            title: title,
            banner_image: banner_image,
            builder_name: builder_name,
            builder_description: builder_description,
            builder_contact: builder_contact,
            form_title: form_title,
            brochure: brochure,
            form_location: form_location,
            prop_price_range: prop_price_range,
            prop_video_url: prop_video_url,
            meta_title: meta_title,
            meta_keyword: meta_keyword,
            meta_description: meta_description,
            prop_status: prop_status,
            master_img: master_img,
            site_img: site_img,
            rera: rera,
            banner_img_list: banner_img_list,
            pricing_floor_plans_img_arr: pricing_floor_plans_img_arr,
            amenities_arr: final_amenities_arr,
            about_prop: about_prop,
            project_status: project_status,
            success_url: success_url,

            cardModel: false,
            photoIndex: 0,
            isOpen: false,
            txtFullName: '',
            txtUserEmail: '',
            txtUserContact: '',
            txtModalFullName: '',
            ddlModalCallbackTime: '',
            txtModalCallbackDate: '',
            ddlCallbackTime: 'Now',
            txtModalUserEmail: '',
            txtModalUserContact: '',
            isBtnEnable: true,
            IsPopupOpen: false,
            IsPopupBtnEnable: true,
            tabHoverCount: 0,
            showSpinner: false,
            modal_addr: '',
            modal_name: '',
            successModel: false,
            dataFetched: false,
            propBlogList: '',
            showPicker: false,
            txtCallbackDate: '',
            selectedDate: '',
            minTime: '00:00:00',
            currentTime: '',
            currentDate: '',
            selectedTime: '',
            showOnLoadModal: false,
            txtContactUsername: '',
            txtContactEmail: '',
            txtContactPhone: '',
            IsPopupContactBtnEnable: true,
            mouseX: 0,
            mouseY: 0,
            popupCounter: 0,
            keyword: '',
            showOnMouseMove: '',
            selling_type: '',
            selling_value: 0,
            selling_duration: 0,
            showSidebar: false,
            theme_color: theme_color,

            txtBrochureUserName: '',
            txtBrochureUserEmail: '',
            txtBrochureMobile: '',
            isBtnBrochureEnable: true,

            txtFloorPlanUserName: '',
            txtFloorPlanUserEmail: '',
            txtFloorPlanMobile: '',
            txtFloorPlanComment: '',
            isBtnFloorPlanEnable: true,
            map_url: '',
            playVideo: false,
            video_url: '',
            offer_head: '',
            offer_body: '',
            offer_price: '',
            offer_footer: '',
            overview_video: '',
            gallery_img_list: [],
            location_map_img: '',
            location_overview: '',
            prop_possession_name: '',
            showBrochureModal: false,
            currentOption: '',
            validationMainContactFormMessage: '',
            validationBrochureFormMessage: '',
            validationFloorFormMessage: '',
            validationContactFormMessage: '',
            validationMobModalFormMessage: '',
            showMobModal: false,
            showChatBtn: 'visible',

            showPriceModal: false

        };
    }
    ShowPriceBreakUpModal = () => {
        this.setState({
            showPriceModal: true
        })
    }
    HidePriceBreakUpModal = () => {
        this.setState({
            showPriceModal: false
        })
    }
    ShowBrochureModal = () => {
        this.setState({
            showBrochureModal: true,
            // showSidebar:false
            // showSidebar:!this.state.showSidebar
        })
    }
    HideBrochureModal = () => {
        this.setState({
            showBrochureModal: false
        })
    }
    SortData = (data) => {
        return data.sort((a, b) => {
            return b.date - a.date;
        });
    }
    componentDidMount = () => {
        sessionStorage.removeItem("current_window")
        var that = this
        document.addEventListener("mousemove", function (e) {
            that.setState({
                mouseX: e.clientX,
                mouseY: e.clientY
            })
        });

        // $(document).mouseleave(function () {
        //     if (that.state.mouseY < 100) {
        //         if (that.state.popupCounter < 1) {

        //         }
        //         that.setState({
        //             popupCounter: that.state.popupCounter + 1
        //         })
        //         if (sessionStorage.getItem("isSubmitContactModal") != 'submitted') {
        //             if (that.state.showOnMouseMove !== 'hide') {
        //                 that.setState({ showOnLoadModal: true, showOnMouseMove: 'show' })
        //             }
        //         }
        //     }
        // });


        // setTimeout(() => {

        //     this.setState({
        //         map_url: 'https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=1%20Grafton%20Street,%20Dublin,%20Ireland+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed'
        //     })

        // }, 3000);


        if (sessionStorage.getItem("contact_modal") === "" || sessionStorage.getItem("contact_modal") === undefined || sessionStorage.getItem("contact_modal") === null) {
            sessionStorage.setItem("contact_modal", "hide")

            setTimeout(() => {

                this.setState({
                    showOnLoadModal: true
                })

            }, 2000);
        }
        else {
            sessionStorage.removeItem("contact_modal")
        }


    }
    HandleLightBoxImage = (e, type) => {
        // console.log(e.target);
        let typeIndex = this.images.findIndex(item => type === item.type);
        let index = 0
        this.images[typeIndex].list.map((item, i) => {
            if (e.target.src === item.img) {
                index = i
            }
        })
        // console.log("index", index)

        if (index > -1) {
            this.setState({
                isOpen: true,
                typeIndex: typeIndex,
                photoIndex: index,
                type: type
            })
        } else {
            this.setState({
                isOpen: true,
                typeIndex: typeIndex,
                photoIndex: 1,
                type: type

            })
        }

    }
    BeforeUnload = () => {
        alert("exit")
    }

    HandleUserInputs = (e) => {
        const val = e.target.value;
        const name = e.target.name;
        console.log(val)
        console.log(name)
        // console.log("e", e)
        this.setState({ [name]: val })
        if (name === 'txtFullName') {
            if (val === '') {
                this.setState({
                    validationMainContactFormMessage: 'Enter your name'
                })
                return false;
            }
            else {
                this.txtFullName = val
            }
        }

        else if (name === 'txtUserContact')
            this.txtUserContact = val
        else if (name === 'txtUserEmail')
            this.txtUserEmail = val
        else if (name === 'txtPopFormUserName')
            this.txtPopFormUserName = val
        else if (name === 'txtPopFormUserContact')
            this.txtPopFormUserContact = val
        else if (name === 'ddlCallbackTime')
            this.ddlCallbackTime = val
        else if (name === 'txtPopFormUserEmail')
            this.txtPopFormUserEmail = val
        // console.log("ddlCallbackTime", this.ddlCallbackTime)

        // if (this.txtFullName !== '' && this.txtUserEmail !== '' && this.txtUserContact !== '' && this.ddlCallbackTime !== '') {
        if (this.txtFullName !== '' && this.txtUserEmail !== '' && this.txtUserContact !== '') {
            this.setState({
                isBtnEnable: false
            })
        }
        else {
            this.setState({
                isBtnEnable: true
            })
        }
    }
    HandleUserInputsBrochure = (e) => {
        const value = e.target.value;
        const name = e.target.name;

        const emailExpn = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var numbers = /^[0-9]+$/;
        if (name === 'txtBrochureUserName') {
            if (value === '') {
                this.setState({
                    validationBrochureFormMessage: 'Enter Name',
                    [name]: ''
                })
                return false
            }
            else {
                this.setState({
                    [name]: value,
                    validationBrochureFormMessage: ''
                })
            }
        }
        else if (name === 'txtBrochureUserEmail') {
            if (value !== '') {
                this.setState({
                    [name]: value
                })
                if (emailExpn.test(value) !== true) {
                    this.setState({
                        validationBrochureFormMessage: 'Enter Valid Email',
                        [name]: value
                    })
                    return false
                }
                else {
                    this.setState({
                        validationBrochureFormMessage: '',
                        [name]: value
                    })
                }
            }
            else {
                this.setState({
                    validationBrochureFormMessage: 'Enter Valid Email',
                    [name]: value
                })
                return false
            }


        }

        else if (name === 'txtBrochureMobile') {
            if (value !== '') {
                if (!value.match(numbers)) {
                    this.setState({
                        [name]: value,
                        validationBrochureFormMessage: 'Enter Valid Mobile Number',
                    })
                    return false
                }
                else {
                    this.setState({
                        [name]: value,
                        validationBrochureFormMessage: '',
                    })
                }
            }
            else {
                this.setState({
                    [name]: value,
                    validationBrochureFormMessage: 'Enter Valid Mobile Number',
                })
                return false
            }
        }

    }
    HandleUserInputsFloorPlan = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        const emailExpn = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var numbers = /^[0-9]+$/;
        if (name === 'txtFloorPlanUserName') {
            if (value === '') {
                this.setState({
                    validationFloorFormMessage: 'Enter Name',
                    [name]: ''
                })
                return false
            }
            else {
                this.setState({
                    [name]: value,
                    validationFloorFormMessage: ''
                })
            }
        }
        else if (name === 'txtFloorPlanUserEmail') {
            if (value !== '') {
                this.setState({
                    [name]: value
                })
                if (emailExpn.test(value) !== true) {
                    this.setState({
                        validationFloorFormMessage: 'Enter Valid Email',
                        [name]: value
                    })
                    return false
                }
                else {
                    this.setState({
                        validationFloorFormMessage: '',
                        [name]: value
                    })
                }
            }
            else {
                this.setState({
                    validationFloorFormMessage: 'Enter Valid Email',
                    [name]: value
                })
                return false
            }


        }

        else if (name === 'txtFloorPlanMobile') {
            if (value !== '') {
                if (!value.match(numbers)) {
                    this.setState({
                        [name]: value,
                        validationFloorFormMessage: 'Enter Valid Mobile Number',
                    })
                    return false
                }
                else {
                    this.setState({
                        [name]: value,
                        validationFloorFormMessage: '',
                    })
                }
            }
            else {
                this.setState({
                    [name]: value,
                    validationFloorFormMessage: 'Enter Valid Mobile Number',
                })
                return false
            }
        }
        if (name === 'txtFloorPlanComment') {
            if (value === '') {
                this.setState({
                    validationFloorFormMessage: 'Enter Comment',
                    [name]: ''
                })
                return false
            }
            else {
                this.setState({
                    [name]: value,
                    validationFloorFormMessage: ''
                })
            }
        }

    }
    SendMail = () => {
        const emailExpn = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var numbers = /^[0-9]+$/;
        if (this.state.txtFullName === '') {
            this.setState({
                validationMainContactFormMessage: 'Enter Name',

            })
            return false
        }
        else {
            this.setState({
                validationMainContactFormMessage: '',

            })
        }
        if (this.state.txtUserEmail === '') {
            this.setState({
                validationMainContactFormMessage: 'Enter Valid Email',

            })
            return false
        }
        else {
            if (emailExpn.test(this.state.txtUserEmail) !== true) {
                this.setState({
                    validationMainContactFormMessage: 'Enter Valid Email',

                })
                return false
            }
            else {
                this.setState({
                    validationMainContactFormMessage: '',

                })
            }
        }

        if (this.state.txtUserContact === '') {
            this.setState({
                validationMainContactFormMessage: 'Enter Valid Mobile Number',

            })
            return false
        }
        else {
            if (!this.state.txtUserContact.match(numbers)) {
                this.setState({
                    validationMainContactFormMessage: 'Enter Valid Mobile Number',

                })
                return false
            }
            else if (this.state.txtUserContact.length !== 10) {
                this.setState({
                    validationMainContactFormMessage: 'Enter Valid Mobile Number',

                })
                return false
            }
            else {
                this.setState({
                    validationMainContactFormMessage: '',

                })
            }
        }
        if (this.state.validationMainContactFormMessage === '') {
            this.setState({
                showSpinner: true,
                isBtnEnable: false
            })


            let data = {
                user_name: this.state.txtFullName,
                user_email: this.state.txtUserEmail,
                user_contact: this.state.txtUserContact,
                prop_name: this.state.form_title,
                call_back_time: '',
                utm_source: ''
            }

            this.SendingLeadMail(data, 'main');
        }
    }
    SendBrochureMail = () => {

        const emailExpn = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var numbers = /^[0-9]+$/;
        if (this.state.txtBrochureUserName === '') {
            this.setState({
                validationBrochureFormMessage: 'Enter Name',

            })
            return false
        }
        else {
            this.setState({
                validationBrochureFormMessage: '',

            })
        }
        if (this.state.txtBrochureUserEmail === '') {
            this.setState({
                validationBrochureFormMessage: 'Enter Valid Email',

            })
            return false
        }
        else {
            if (emailExpn.test(this.state.txtBrochureUserEmail) !== true) {
                this.setState({
                    validationBrochureFormMessage: 'Enter Valid Email',

                })
                return false
            }
            else {
                this.setState({
                    validationBrochureFormMessage: '',

                })
            }
        }

        if (this.state.txtBrochureMobile === '') {
            this.setState({
                validationBrochureFormMessage: 'Enter Valid Mobile Number',

            })
            return false
        }
        else {
            if (!this.state.txtBrochureMobile.match(numbers)) {
                this.setState({
                    validationBrochureFormMessage: 'Enter Valid Mobile Number',

                })
                return false
            }
            else if (this.state.txtBrochureMobile.length !== 10) {
                this.setState({
                    validationBrochureFormMessage: 'Enter Valid Mobile Number',

                })
                return false
            }
            else {
                this.setState({
                    validationBrochureFormMessage: '',

                })
            }
        }



        if (this.state.validationBrochureFormMessage === '') {
            // alert(1)
            this.setState({
                currentOption: 'brochure',
                showSpinner: true,
                isBtnBrochureEnable: false
            })


            // sessionStorage.setItem("currentOption",'brochure')
            // sessionStorage.setItem("brochureLink",this.db_server_path + 'images/uploads/property/'+this.state.brochure)
            let data = {
                user_name: this.state.txtBrochureUserName,
                user_email: this.state.txtBrochureUserEmail,
                user_contact: this.state.txtBrochureMobile,
                prop_name: this.state.form_title,
                user_comment: '',
                call_back_time: '',
                utm_source: ''
            }

            this.SendingLeadMail(data, 'main');
        }
    }
    SendSitePlanMail = () => {
        const emailExpn = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var numbers = /^[0-9]+$/;
        if (this.state.txtFloorPlanUserName === '') {
            this.setState({
                validationFloorFormMessage: 'Enter Name',

            })
            return false
        }
        else {
            this.setState({
                validationFloorFormMessage: '',

            })
        }
        if (this.state.txtFloorPlanUserEmail === '') {
            this.setState({
                validationFloorFormMessage: 'Enter Valid Email',

            })
            return false
        }
        else {
            if (emailExpn.test(this.state.txtFloorPlanUserEmail) !== true) {
                this.setState({
                    validationFloorFormMessage: 'Enter Valid Email',

                })
                return false
            }
            else {
                this.setState({
                    validationFloorFormMessage: '',

                })
            }
        }

        if (this.state.txtFloorPlanMobile === '') {
            this.setState({
                validationFloorFormMessage: 'Enter Valid Mobile Number',

            })
            return false
        }
        else {
            if (!this.state.txtFloorPlanMobile.match(numbers)) {
                this.setState({
                    validationFloorFormMessage: 'Enter Valid Mobile Number',

                })
                return false
            }
            else if (this.state.txtFloorPlanMobile.length !== 10) {
                this.setState({
                    validationFloorFormMessage: 'Enter Valid Mobile Number',

                })
                return false
            }
            else {
                this.setState({
                    validationFloorFormMessage: '',

                })
            }
        }
        if (this.state.txtFloorPlanComment === '') {
            this.setState({
                validationFloorFormMessage: 'Enter Comment',

            })
            return false
        }
        else {
            this.setState({
                validationFloorFormMessage: '',

            })
        }
        if (this.state.validationFloorFormMessage === '') {
            this.setState({
                currentOption: '',
                showSpinner: true,
                isBtnFloorPlanEnable: false
            })

            let data = {
                user_name: this.state.txtFloorPlanUserName,
                user_email: this.state.txtFloorPlanUserEmail,
                user_contact: this.state.txtFloorPlanMobile,
                prop_name: this.state.form_title,
                user_comment: this.state.txtFloorPlanComment,
                call_back_time: '',
                utm_source: ''
            }

            this.SendingLeadMail(data, 'main');
        }
    }
    downloadURI = (uri, name) => {
        var link = document.createElement("a");
        link.download = name;
        link.href = this.db_server_path + 'images/uploads/property/' + uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    }
    SendModalMail = () => {

        const emailExpn = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var numbers = /^[0-9]+$/;
        if (this.state.txtModalFullName === '') {
            this.setState({
                validationMobModalFormMessage: 'Enter Name',

            })
            return false
        }
        else {
            this.setState({
                validationMobModalFormMessage: '',

            })
        }
        if (this.state.txtModalUserEmail === '') {
            this.setState({
                validationMobModalFormMessage: 'Enter Valid Email',

            })
            return false
        }
        else {
            if (emailExpn.test(this.state.txtModalUserEmail) !== true) {
                this.setState({
                    validationMobModalFormMessage: 'Enter Valid Email',

                })
                return false
            }
            else {
                this.setState({
                    validationMobModalFormMessage: '',

                })
            }
        }

        if (this.state.txtModalUserContact === '') {
            this.setState({
                validationMobModalFormMessage: 'Enter Valid Mobile Number',

            })
            return false
        }
        else {
            if (!this.state.txtModalUserContact.match(numbers)) {
                this.setState({
                    validationMobModalFormMessage: 'Enter Valid Mobile Number',

                })
                return false
            }
            else if (this.state.txtModalUserContact.length !== 10) {
                this.setState({
                    validationMobModalFormMessage: 'Enter Valid Mobile Number',

                })
                return false
            }
            else {
                this.setState({
                    validationMobModalFormMessage: '',

                })
            }
        }


        if (this.state.validationMobModalFormMessage === '') {

            this.setState({
                showSpinner: true,
                isBtnEnable: false
            })
            let userBooking = "";


            let data = {
                user_name: this.state.txtModalFullName,
                user_email: this.state.txtModalUserEmail,
                user_contact: this.state.txtModalUserContact,
                prop_name: this.state.form_title,
                call_back_time: userBooking,
                utm_source: ''
            }

            this.SendingLeadMail(data, 'modal');
        }
    }
    SendContactModalMail = () => {
        const emailExpn = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var numbers = /^[0-9]+$/;
        if (this.state.txtContactUsername === '') {
            this.setState({
                validationContactFormMessage: 'Enter Name',

            })
            return false
        }
        else {
            this.setState({
                validationContactFormMessage: '',

            })
        }
        if (this.state.txtContactEmail === '') {
            this.setState({
                validationContactFormMessage: 'Enter Valid Email',

            })
            return false
        }
        else {
            if (emailExpn.test(this.state.txtContactEmail) !== true) {
                this.setState({
                    validationContactFormMessage: 'Enter Valid Email',

                })
                return false
            }
            else {
                this.setState({
                    validationContactFormMessage: '',

                })
            }
        }

        if (this.state.txtContactPhone === '') {
            this.setState({
                validationContactFormMessage: 'Enter Valid Mobile Number',

            })
            return false
        }
        else {
            if (!this.state.txtContactPhone.match(numbers)) {
                this.setState({
                    validationContactFormMessage: 'Enter Valid Mobile Number',

                })
                return false
            }
            else if (this.state.txtContactPhone.length !== 10) {
                this.setState({
                    validationContactFormMessage: 'Enter Valid Mobile Number',

                })
                return false
            }
            else {
                this.setState({
                    validationContactFormMessage: '',

                })
            }
        }
        if (this.state.validationContactFormMessage === '') {
            this.setState({
                showSpinner: true,
                IsPopupContactBtnEnable: false
            })

            let data = {
                user_name: this.state.txtContactUsername,
                user_email: this.state.txtContactEmail,
                user_contact: this.state.txtContactPhone,
                prop_name: this.state.form_title,
                utm_source: 'PopUp'
            }

            sessionStorage.setItem("current_window", 'contact_modal')

            this.SendingLeadMail(data, 'modal-popup');
        }
    }
    HandleDateTime = (field) => {
        // console.log("filed", field)
        var today = new Date();
        // today = new Date(today.getTime() + 10*60000);
        let finaldatetime = '';
        let timeoptions = {
            lang: 'EN', // default 'EN'
            format: 'HH:mm', // default 'HH:mm'
            // default: '12:27', // default `new Date()`. If `default` type is string, then it will be parsed to `Date` instance by `format` . Or it can be a `Date` instance
            minuteStep: 5, // default 5. Select minutes step, must be one of [1, 5, 10]
            min: '08:00', // min time value, `{String | Date}`, default `new Date(1900, 0, 1, 0, 0, 0, 0)`
            max: '22:00' // max time value, `{String | Date}`, default `new Date(2100, 11, 31, 23, 59, 59, 999)`

        }
        let config = {
            // day: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            // shortDay: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
            // MDW: 'D, MM-d',
            // YM: 'yyyy-M',
            OK: 'OK',
            CANCEL: '',
            CLEAR: ''

        }

        // var DateTimePicker = require('date-time-picker')
        // let that = this;
        // var timePicker = new DateTimePicker.Time(timeoptions, config)
        // let hour = ((today.getHours()).toString()).length == 1 ? '0' + (today.getHours()) : (today.getHours());
        // let min = (today.getMinutes().toString()).length == 1 ? '0' + today.getMinutes() : today.getMinutes();
        // let time = hour + ":" + min;
        // timePicker.on('selected', function (formatTime, now) {
        //     // formatTime = 18:30
        //     // now = Date instance -> Wed Oct 19 2016 18:30:13 GMT+0800 (CST)
        //     finaldatetime = finaldatetime + ' ' + formatTime;


        //     that.setState({
        //         selectedTime: formatTime,
        //         currentTime: time,
        //         [field]: finaldatetime
        //     })

        //     // that.editDataObj['txtBookingDate'] = finaldatetime;
        //     // that.setState({ txtBookingDate: finaldatetime })
        // })
        // timePicker.on('cleared', function () {
        //     // clicked clear btn
        //     finaldatetime = finaldatetime;

        //     that.setState({ [field]: finaldatetime })
        //     // that.editDataObj['txtBookingDate'] = finaldatetime;
        //     // that.setState({ txtBookingDate: finaldatetime })
        // })


        // let mon = ((today.getMonth() + 1).toString()).length == 1 ? '0' + (today.getMonth() + 1) : (today.getMonth() + 1);
        // let day = (today.getDate().toString()).length == 1 ? '0' + today.getDate() : today.getDate();
        // let date = today.getFullYear() + "-" + mon + "-" + day;


        // // console.log("time", time)
        // let options = {
        //     lang: 'EN', // default 'EN'. One of 'EN', 'zh-CN'
        //     format: 'yyyy-MM-dd', // default 'yyyy-MM-dd'
        //     // default: '2016-10-19', // default `new Date()`. If `default` type is string, then it will be parsed to `Date` instance by `format` . Or it can be a `Date` instance
        //     min: date, // min date value, `{String | Date}`, default `new Date(1900, 0, 1, 0, 0, 0, 0)`
        //     // max: '2018-11-08' // max date value, `{String | Date}`, default `new Date(2100, 11, 31, 23, 59, 59, 999)`
        // }

        // var datePicker = new DateTimePicker.Date(options, config)
        // datePicker.on('selected', function (formatDate, now) {

        //     // formatData = 2016-10-19
        //     // now = Date instance -> Wed Oct 19 2016 20:28:12 GMT+0800 (CST)
        //     finaldatetime = finaldatetime + formatDate;


        //     that.setState({

        //         selectedDate: finaldatetime,
        //         currentDate: date
        //     })


        // })

        // datePicker.on('cleared', function () {
        //     // clicked clear btn
        //     finaldatetime = finaldatetime;
        // })
    }
    HandleChange = (e) => {
        const emailExpn = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var numbers = /^[0-9]+$/;
        if (e.target.name === 'txtFullName') {
            if (e.target.value === '') {
                this.setState({
                    validationMainContactFormMessage: 'Enter Name',
                    [e.target.name]: ''
                })
                return false
            }
            else {
                this.setState({
                    [e.target.name]: e.target.value,
                    validationMainContactFormMessage: ''
                })
            }
        }
        else if (e.target.name === 'txtUserEmail') {
            if (e.target.value !== '') {
                this.setState({
                    [e.target.name]: e.target.value
                })
                if (emailExpn.test(e.target.value) !== true) {
                    this.setState({
                        validationMainContactFormMessage: 'Enter Valid Email',
                        [e.target.name]: e.target.value
                    })
                    return false
                }
                else {
                    this.setState({
                        validationMainContactFormMessage: '',
                        [e.target.name]: e.target.value
                    })
                }
            }
            else {
                this.setState({
                    validationMainContactFormMessage: 'Enter Valid Email',
                    [e.target.name]: e.target.value
                })
                return false
            }


        }

        else if (e.target.name === 'txtUserContact') {
            if (e.target.value !== '') {
                if (!e.target.value.match(numbers)) {
                    this.setState({
                        [e.target.name]: e.target.value,
                        validationMainContactFormMessage: 'Enter Valid Mobile Number',
                    })
                    return false
                }
                else {
                    this.setState({
                        [e.target.name]: e.target.value,
                        validationMainContactFormMessage: '',
                    })
                }
            }
            else {
                this.setState({
                    [e.target.name]: e.target.value,
                    validationMainContactFormMessage: 'Enter Valid Mobile Number',
                })
                return false
            }
        }



    }

    HandleChangeModal = (e) => {
        const value = e.target.value;
        const name = e.target.name;

        const emailExpn = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var numbers = /^[0-9]+$/;
        if (name === 'txtModalFullName') {
            if (value === '') {
                this.setState({
                    validationMobModalFormMessage: 'Enter Name',
                    [name]: ''
                })
                return false
            }
            else {
                this.setState({
                    [name]: value,
                    validationMobModalFormMessage: ''
                })
            }
        }
        else if (name === 'txtModalUserEmail') {
            if (value !== '') {
                this.setState({
                    [name]: value
                })
                if (emailExpn.test(value) !== true) {
                    this.setState({
                        validationMobModalFormMessage: 'Enter Valid Email',
                        [name]: value
                    })
                    return false
                }
                else {
                    this.setState({
                        validationMobModalFormMessage: '',
                        [name]: value
                    })
                }
            }
            else {
                this.setState({
                    validationMobModalFormMessage: 'Enter Valid Email',
                    [name]: value
                })
                return false
            }


        }

        else if (name === 'txtModalUserContact') {
            if (value !== '') {
                if (!value.match(numbers)) {
                    this.setState({
                        [name]: value,
                        validationMobModalFormMessage: 'Enter Valid Mobile Number',
                    })
                    return false
                }
                else {
                    this.setState({
                        [name]: value,
                        validationMobModalFormMessage: '',
                    })
                }
            }
            else {
                this.setState({
                    [name]: value,
                    validationMobModalFormMessage: 'Enter Valid Mobile Number',
                })
                return false
            }
        }

    }

    SendingLeadMail = (data, from) => {
        // alert(1)
        let utm_source = "Organic";
        if (from === 'modal-popup') {
            utm_source = 'PopUp'
        }

        let utm_term = "";
        let details_url = window.location.href;
        if (details_url.indexOf('utm_source') > -1) {
            let newUrl = details_url.split("utm_source=")
            newUrl = newUrl[1]
            if (newUrl.includes('&'))
                utm_source = newUrl.substring(newUrl, newUrl.indexOf("&"));
            else
                utm_source = newUrl
            utm_source = utm_source.replace(/%2B/g, '')
            utm_source = utm_source.replace(/%20/g, ' ')
        }
        if (details_url.indexOf('utm_term') > -1) {
            let newUrl1 = details_url.split("utm_term=")
            newUrl1 = newUrl1[1]
            if (newUrl1.includes('&'))
                utm_term = newUrl1.substring(newUrl1, newUrl1.indexOf("&"));
            else
                utm_term = newUrl1

            utm_term = utm_term.replace(/%2B/g, '')
            utm_term = utm_term.replace(/%20/g, ' ')
        }
        data.utm_source = utm_source;
        if (utm_term !== '')
            data.keyword = utm_term;

        let mobile_no = data.user_contact;




        let that = this;


        axios.post('https://www.pinclick.com/admin/property/leadrequest/json/outside', data)
            // axios.post('http://localhost:3000/admin/property/leadrequest/json/outside', data)
            .then(function (response) {
                if (sessionStorage.getItem("current_window") !== undefined || sessionStorage.getItem("current_window") !== null || sessionStorage.getItem("current_window") !== '') {

                    sessionStorage.setItem("isSubmitContactModal", 'submitted')
                    that.setState({
                        showOnLoadModal: false
                    })

                }
                if (that.state.currentOption === 'brochure') {
                    // alert(1)
                    var link = document.createElement("a");
                    link.target = "_blank";
                    link.download = 'Brochure.pdf';
                    link.href = that.db_server_path + 'images/uploads/property/' + that.state.brochure;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }


                sessionStorage.setItem('success_url', that.state.success_url);
                sessionStorage.setItem('current_url', '');
                that.setState({
                    // successModel: true,
                    showSpinner: false,
                    txtFullName: '',
                    txtUserContact: '',
                    txtUserEmail: '',
                    txtBrochureMobile: '',
                    txtBrochureUserEmail: '',
                    txtBrochureUserName: '',
                    txtFloorPlanComment: '',
                    txtFloorPlanMobile: '',
                    txtFloorPlanUserEmail: '',
                    txtFloorPlanUserName: '',
                    currentOption: '',
                })


                let url = window.location.href;


                url = url.split('?')[0];
                var lastChar = url.substr(-1); // Selects the last character
                if (lastChar !== '/') {         // If the last character is not a slash
                    url = url + '/successful/?' + mobile_no;            // Append a slash to it.
                    // url = url + '/successful/?' + sha512(mobile_no);            // Append a slash to it.
                }
                else
                    url = url + 'successful/?' + mobile_no;
                // url = url + 'successful/?' + sha512(mobile_no);
                // 
                // console.log('@@@@@@@@@@@@@@@@@-----' + url);
                window.location.href = url;
                // if(this.state.currentOption==='brochure'){
                //     var a = document.createElement("a");
                //     let url=this.state.brochure
                //     alert(url)
                //     a.href = url;
                //     let fileName = url.split("/").pop();
                //     a.download = fileName;
                //     document.body.appendChild(a);
                //     a.click();
                //     window.URL.revokeObjectURL(url);
                //     a.remove();

                //    }

            })
            .catch(function (error) {
                console.log(error);
                //alert('Something went wrong. Please try again later.');
                that.setState({
                    showSpinner: false
                })
            });
    }
    ShowTimePicker = () => {
        this.setState({
            showPicker: true
        })
    }
    HideTimePicker = () => {
        this.setState({
            showPicker: false
        })
    }
    HideOnLoadModal = () => {
        this.setState({ showOnLoadModal: false })
        if (this.state.showOnMouseMove === 'show') {
            this.setState({
                showOnMouseMove: 'hide'
            })
        }
    }
    HandleChangeContactModal = (e) => {
        const value = e.target.value;
        const name = e.target.name;

        const emailExpn = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var numbers = /^[0-9]+$/;
        if (name === 'txtContactUsername') {
            if (value === '') {
                this.setState({
                    validationContactFormMessage: 'Enter Name',
                    [name]: ''
                })
                return false
            }
            else {
                this.setState({
                    [name]: value,
                    validationContactFormMessage: ''
                })
            }
        }
        else if (name === 'txtContactEmail') {
            if (value !== '') {
                this.setState({
                    [name]: value
                })
                if (emailExpn.test(value) !== true) {
                    this.setState({
                        validationContactFormMessage: 'Enter Valid Email',
                        [name]: value
                    })
                    return false
                }
                else {
                    this.setState({
                        validationContactFormMessage: '',
                        [name]: value
                    })
                }
            }
            else {
                this.setState({
                    validationContactFormMessage: 'Enter Valid Email',
                    [name]: value
                })
                return false
            }


        }

        else if (name === 'txtContactPhone') {
            if (value !== '') {
                if (!value.match(numbers)) {
                    this.setState({
                        [name]: value,
                        validationContactFormMessage: 'Enter Valid Mobile Number',
                    })
                    return false
                }
                else {
                    this.setState({
                        [name]: value,
                        validationContactFormMessage: '',
                    })
                }
            }
            else {
                this.setState({
                    [name]: value,
                    validationContactFormMessage: 'Enter Valid Mobile Number',
                })
                return false
            }
        }




    }
    ShowSideBar = (x) => {
        var element = document.getElementById("projectRoot");
        element.classList.toggle("change");

        // x.classList.toggle("change");
        this.setState({
            showSidebar: !this.state.showSidebar
        })
    }
    GetVideoURL = (url) => {

        this.setState({
            playVideo: true,
            video_url: url
        })
    }
    HideVideoModal = () => {
        this.setState({
            playVideo: false
        })
    }
    ShowMobModal = () => {
        // alert(1)
        this.setState({
            showMobModal: true,
            showChatBtn: 'hidden'
        })
    }
    HideMobModal = () => {
        this.setState({
            showMobModal: false,
            showChatBtn: 'visible'
        })
    }
    render() {
        // console.log("state", this.state)
        const { photoIndex, isOpen, typeIndex } = this.state;
        let whatsapp_link = 'https://wa.me/919353516504?text=Hello, I need more details about ' + this.state.title
        // document.title = "Hello World";

        let msg = "I'm Interested In " + this.state.form_title + " ," + this.state.form_location + ". Please Share Details.";
        msg = encodeURI(msg);
        // const current_url = `https://wa.me//send?phone=+918047193000&text=${msg}`;
        const current_url = `https://api.whatsapp.com/send?phone=+918047193000&text=${msg}`;

        return (
            <React.Fragment>
                {this.state.showSidebar === true ?
                    <Sidebar theme_color={this.state.theme_color} ShowBrochureModal={this.ShowBrochureModal} cache {...this.props} path={this.server_path} db_path={this.db_server_path} logo={this.state.logo} ShowSideBar={this.ShowSideBar} /> : null}

                <div className="container-fluid" >
                    <div className="row">
                        <Suspense fallback={<img src={require('./pin_loader.gif')} style={{ width: '150px', marginLeft: '40%', marginTop: '10%' }} />}>
                            <Header theme_color={this.state.theme_color} ShowBrochureModal={this.ShowBrochureModal} cache {...this.props} path={this.server_path} db_path={this.db_server_path} logo={this.state.logo} ShowSideBar={this.ShowSideBar} />
                        </Suspense>
                    </div>

                    <div>
                        {this.state.showSpinner ?
                            <div style={{ color: 'red', fontSize: 25, position: 'fixed', left: 45 + '%', top: 50 + '%', zIndex: 9999 }}>
                                <i className="fa fa-spinner fa-spin" /> Loading...</div>
                            : ''}
                        <div className="row">
                            <Suspense fallback={<img src={require('./pin_loader.gif')} style={{ width: '150px', marginLeft: '40%', marginTop: '10%' }} />}>
                                <div id="projectRoot" className="container-fluid mob-bottom" style={{ padding: '0px', background: '#f2f2f2' }}>
                                    <div className="row" >
                                        <div className="col-12 col-sm-12 col-lg-8 col-md-8" style={{ paddingRight: 0 }}>

                                            <SliderImgCtrl theme_color={this.state.theme_color} {...this.props} path={this.server_path} logo={this.state.logo} db_path={this.db_server_path}
                                                mystate={this.state}
                                                title={this.state.form_title} banner_img_list={this.state.banner_img_list}
                                            />
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-lg-4 col-md-4  banner-right-img hide-mob">
                                        </div>

                                        <div className="col-12 col-sm-12 col-lg-8 col-md-8">
                                            <div className='slider-body'>
                                                <SliderBottom ShowBrochureModal={this.ShowBrochureModal} theme_color={this.state.theme_color} prop_location={this.state.form_location} prop_price_range={this.state.prop_price_range}  {...this.props} path={this.server_path} logo={this.state.logo} db_path={this.db_server_path} prop_spread_area={this.state.prop_spread_area}
                                                    prop_sq_ft_range={this.state.prop_sq_ft_range} title={this.state.form_title} builder_name={this.state.builder_name}
                                                    validationFloorFormMessage={this.state.validationFloorFormMessage} whatsapp_link={whatsapp_link} SendSitePlanMail={this.SendSitePlanMail} HandleUserInputsFloorPlan={this.HandleUserInputsFloorPlan}  {...this.props} path={this.server_path} db_path={this.db_server_path} title={this.state.form_title}
                                                    all_bhk={this.state.all_bhk} prop_type_name={this.state.prop_type_name} pricing_floor_plans_img_arr={this.state.pricing_floor_plans_img_arr} mystate={this.state}
                                                    ShowPriceBreakUpModal={this.ShowPriceBreakUpModal}
                                                />
                                            </div>
                                            <div className='main-body'>

                                                <OverviewCtrl ShowBrochureModal={this.ShowBrochureModal}  {...this.props} path={this.server_path} db_path={this.db_server_path} prop_spread_area={this.state.prop_spread_area}
                                                    prop_sq_ft_range={this.state.prop_sq_ft_range} about_prop={this.state.about_prop} title={this.state.form_title} builder_name={this.state.builder_name}
                                                    rera={this.state.rera} GetVideoURL={this.GetVideoURL} HandleUserInputsBrochure={this.HandleUserInputsBrochure} mystate={this.state} builder_description={this.state.builder_description} />

                                                <SitePlan theme_color={this.state.theme_color} HandleLightBoxImage={this.HandleLightBoxImage} HandleUserInputsFloorPlan={this.HandleUserInputsFloorPlan}  {...this.props} path={this.server_path} db_path={this.db_server_path} title={this.state.form_title}
                                                    all_bhk={this.state.all_bhk} prop_type_name={this.state.prop_type_name} pricing_floor_plans_img_arr={this.state.pricing_floor_plans_img_arr} mystate={this.state} master_img={this.state.master_img} />

                                                <MoreInfo ShowBrochureModal={this.ShowBrochureModal} theme_color={this.state.theme_color}  {...this.props} path={this.server_path} db_path={this.db_server_path} prop_spread_area={this.state.prop_spread_area}
                                                    prop_sq_ft_range={this.state.prop_sq_ft_range} about_prop={this.state.about_prop} title={this.state.form_title} builder_name={this.state.builder_name} mystate={this.state} />



                                                <AmentiesCtrl {...this.props} path={this.server_path} db_path={this.db_server_path} title={this.state.form_title}
                                                    amenities_arr={this.state.amenities_arr} amenities_desc={this.state.amenities_desc} static_amenities={this.static_amenities} />

                                                <MapView HandleLightBoxImage={this.HandleLightBoxImage} prop_location={this.state.form_location} {...this.props} path={this.server_path} db_path={this.db_server_path} title={this.state.form_title}
                                                    location_map_img={this.state.location_map_img} location_overview={this.state.location_overview} />
                                                <VideoCtrl GetVideoURL={this.GetVideoURL} {...this.props} path={this.server_path} db_path={this.db_server_path} title={this.state.form_title} prop_video_url={this.state.prop_video_url} />
                                                {this.state.showOnLoadModal === true ?
                                                    <ContactModal validationContactFormMessage={this.state.validationContactFormMessage} cache visible='block' HandleClose={this.HideOnLoadModal} mystate={this.state} IsPopupContactBtnEnable={this.state.IsPopupContactBtnEnable}
                                                        txtContactUsername={this.state.txtContactUsername} txtContactEmail={this.state.txtContactEmail} txtContactPhone={this.state.txtContactPhone} HandleChangeContactModal={this.HandleChangeContactModal} logo={this.state.logo}
                                                        SendContactModalMail={this.SendContactModalMail} theme_color={this.state.theme_color}
                                                    /> : null}
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-lg-4 col-md-4 sticky-form" id="divContactForm" >
                                            <FormCtrl theme_color={this.state.theme_color} validationMainContactFormMessage={this.state.validationMainContactFormMessage} cache {...this.props} SendMail={this.SendMail} HandleUserInputs={this.HandleChange} isBtnEnable={this.state.isBtnEnable} txtFullName={this.state.txtFullName} txtUserEmail={this.state.txtUserEmail} txtUserContact={this.state.txtUserContact}
                                                txtCallbackDate={this.state.txtCallbackDate} ddlCallbackTime={this.state.ddlCallbackTime} showPicker={this.state.showPicker} prop_name={this.state.form_title} prop_location={this.state.form_location}
                                            />
                                        </div>


                                    </div>
                                </div>
                            </Suspense>
                        </div>

                    </div>
                    {this.state.playVideo === true ?
                        <Suspense fallback={<img src={require('./pin_loader.gif')} style={{ width: '150px', marginLeft: '40%', marginTop: '10%' }} />}>
                            <VideoModal HideVideoModal={this.HideVideoModal} prop_video_url={this.state.video_url} />
                        </Suspense>
                        : null
                    }

                    <div className="row">

                        <div id='divFooter' className='footer-main-div'>
                            <div className='footer-container'>
                                <div className='row'>
                                    <div className="col-12 col-sm-12 col-lg-8 col-md-8">
                                        <div className='footer-content'>
                                            Pin Click is a ZERO brokerage platform with a mission is to simplify the home search. We pride ourselves in our technology, people and process enabled solutions to help customers search, view and move into their Dream home. As Marketing Partners to top developers in Bengaluru, Pune, Gurgaon and Mumbai we have supported over 1500 customers in finding their Dream homes since 2013. Our Team of property advisors help customers through the journey of buying a home - right from sharing property details, face to face or video conference driven discussions, site visits to shortlisted projects, inventory support and best price possible.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='row'>
                        <button style={{ backgroundColor: this.state.theme_color, visibility: this.state.showChatBtn }} className='btn-container ' id='btn-container' onClick={() => this.ShowMobModal()} >
                            <img src={require('../assets/images/sms.png')} style={{ objectFit: 'none', height: '90%' }} />

                        </button>
                    </div>
                </div>

                {this.state.showMobModal === true ?
                    <MobFormModal open={this.state.showMobModal} HideMobModal={this.HideMobModal} theme_color={this.state.theme_color} validationMobModalFormMessage={this.state.validationMobModalFormMessage} cache path={this.server_path} db_path={this.db_server_path} SendModalMail={this.SendModalMail} HandleChangeModal={this.HandleChangeModal} IsPopupBtnEnable={this.state.IsPopupBtnEnable}
                        txtModalFullName={this.state.txtModalFullName} txtModalUserEmail={this.state.txtModalUserEmail} txtModalUserContact={this.state.txtModalUserContact}
                        txtModalCallbackDate={this.state.txtModalCallbackDate} ddlModalCallbackTime={this.state.ddlModalCallbackTime} showPicker={this.state.showPicker}
                        prop_name={this.state.form_title} prop_location={this.state.form_location} />
                    : null}
                {isOpen && (
                    <Lightbox
                        keyRepeatLimit={100}
                        animationDuration={30}
                        animationDisabled={false}
                        mainSrc={this.images[typeIndex].list[photoIndex].img}
                        nextSrc={this.images[typeIndex].list[(photoIndex + 1) % this.images.length]}
                        prevSrc={this.images[typeIndex].list[(photoIndex + this.images.length - 1) % this.images.length]}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + this.images.length - 1) % this.images.length,
                            })
                        }

                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % this.images.length,
                            })
                        }
                    />
                )}
                {this.state.showBrochureModal === true ?
                    <BrochureModal open={this.state.showBrochureModal} validationBrochureFormMessage={this.state.validationBrochureFormMessage} SendBrochureMail={this.SendBrochureMail} HideBrochureModal={this.HideBrochureModal} HandleUserInputsBrochure={this.HandleUserInputsBrochure} mystate={this.state}
                        theme_color={this.state.theme_color}
                    />
                    : null}
                {this.state.showPriceModal === true ?
                    <PriceBreakUpModal open={this.state.showPriceModal} validationBrochureFormMessage={this.state.validationBrochureFormMessage} HidePriceBreakUpModal={this.HidePriceBreakUpModal} HandleUserInputsBrochure={this.HandleUserInputsBrochure} mystate={this.state}
                        theme_color={this.state.theme_color} SendSitePlanMail={this.SendSitePlanMail} HandleUserInputsFloorPlan={this.HandleUserInputsFloorPlan} validationFloorFormMessage={this.state.validationFloorFormMessage}
                    />
                    : null}
            </React.Fragment>

        )
    }
}