export const colorArray=[
    {
        'builder_name':'Birla Estates',
        'color':'#821974'
    },
    {
        'builder_name':'Shriram Properties',
        'color':'#127ec2'
    },
    {
        'builder_name':'Prestige Group',
        'color':'#A88843'
    },
    {
        'builder_name':'Dosti Realty',
        'color':'#bd332f'
    },
    {
        'builder_name':'Brigade Group',
        'color':'#0090D3'
    },
    {
        'builder_name':'Rohan Group',
        'color':'#3c3c3c'
    },
    {
        'builder_name':'Lodha',
        'color':'#bd9234'
    },
    {
        'builder_name':'VTP Realty',
        'color':'#7787be'
    },
    {
        'builder_name':'Vilas Javedkar Developers',
        'color':'#232323'
    },
    {
        'builder_name':'Emaar India',
        'color':'#071c35'
    },
    {
        'builder_name':'Vaswani',
        'color':'#1FAE58'
    },
     {
        'builder_name':'Rustomjee',
        'color':'#b98e33'
    },
     {
        'builder_name':'Goyal And Co.',
        'color':'#231f20'
    },
     {
        'builder_name':'Ozone Group',
        'color':'#42A2CE'
    },
     {
        'builder_name':'Total Enviroment',
        'color':'#000'
    },
     {
        'builder_name':'Assetz Property',
        'color':'#8dc641'
    },
     {
        'builder_name':'Godrej Group',
        'color':'#3296ce'
    },
     {
        'builder_name':'Tata Housing',
        'color':'#3e66f9'
    },
     {
        'builder_name':'Ajmera Group',
        'color':'#C99F35'
    },
     {
        'builder_name':'ATS Projects',
        'color':'#e63a3b'
    },
     // {
    //     'builder_name':'Birla Estates',
    //     'color':'#821974'
    // },
     // {
    //     'builder_name':'Birla Estates',
    //     'color':'#821974'
    // },
     // {
    //     'builder_name':'Birla Estates',
    //     'color':'#821974'
    // },
     // {
    //     'builder_name':'Birla Estates',
    //     'color':'#821974'
    // },
     // {
    //     'builder_name':'Birla Estates',
    //     'color':'#821974'
    // },
     // {
    //     'builder_name':'Birla Estates',
    //     'color':'#821974'
    // },
     // {
    //     'builder_name':'Birla Estates',
    //     'color':'#821974'
    // },
     // {
    //     'builder_name':'Birla Estates',
    //     'color':'#821974'
    // },
     // {
    //     'builder_name':'Birla Estates',
    //     'color':'#821974'
    // },
     // {
    //     'builder_name':'Birla Estates',
    //     'color':'#821974'
    // },
     // {
    //     'builder_name':'Birla Estates',
    //     'color':'#821974'
    // }
]