import React from 'react'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import '../../assets/css/style.css'
export default class BrochureModal extends React.Component{
    constructor(props){
        super(props)
        // alert(1)
    }
    render(){
        return(
            <Modal open={this.props.open} onClose={this.props.HideBrochureModal} center
            classNames={{
                // overlay: 'customOverlay',
                modal: 'customModal',
              }}>
        
                             <div class="modal-header brochure-modal-header">
                               <h5 class="modal-title brochure-modal-title" id="exampleModalLongTitle">Download Brochure</h5>
                               {/* <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={this.props.HideBrochureModal}>
                                 <span aria-hidden="true">&times;</span>
                               </button> */}
                             </div>
                             <div class="modal-body brochure-modal-body">
                                 <div className='row'>
                                 <div className="col-sm-12 col-md-12 col-lg-12">
                                               <form style={{ marginTop: -38 }}>
                                                   <div class="form-row align-items-center">
                                                       <div class="col-sm-12 col-md-12 col-lg-12">
                                                           <label class="sr-only" for="txtBrochureUserName" className='form-label-contact'> Name </label>
                                                           <input type="text" class="form-control j_forminputf" id="txtBrochureUserName" name="txtBrochureUserName" onChange={this.props.HandleUserInputsBrochure} placeholder="Enter name" value={this.props.mystate.txtBrochureUserName} />
                                                       </div>
                       
                                                       <div class="col-sm-12 col-md-12 col-lg-12">
                                                           <label class="sr-only" for="txtBrochureUserEmail"  className='form-label-contact'>Email ID</label>
                                                           <input sty type="email" class="form-control j_forminputf " id="txtBrochureUserEmail" name="txtBrochureUserEmail" onChange={this.props.HandleUserInputsBrochure} placeholder="Enter email ID" value={this.props.mystate.txtBrochureUserEmail} />
                                                       </div>
                       
                                                       <div class="col-sm-12 col-md-12 col-lg-12">
                                                           <label class="sr-only" for="txtBrochureMobile"  className='form-label-contact'>Mobile no</label>
                                                           <div class="input-group">
                                                               <div class="input-group-prepend">
                                                                   <div class="input-group-text bg-light" style={{ borderRadius: 0 }}>+91</div>
                                                               </div>
                                                               <input type="text" class="form-control j_forminputf  " maxLength="10" id="txtBrochureMobile" name="txtBrochureMobile" onChange={this.props.HandleUserInputsBrochure} value={this.props.txtBrochureMobile} placeholder="Enter mobile number" />
                                                           </div>
                                                       </div>
                                                       {this.props.validationBrochureFormMessage!==''?
                                    <div className="col-sm-12 col-md-12 col-lg-12">
                                        <div className="errorMessage">{this.props.validationBrochureFormMessage}</div>
                                    </div>:null}
                                                      
                                                       
                                                       <div class="col-sm-12 col-md-12 col-lg-12" style={{marginTop:'24px'}}>
                                                           <button type="button" className="j_btn" style={{backgroundColor:this.props.theme_color}}  onClick={()=>this.props.SendBrochureMail()}>Send Now</button>
                                                           
                                                       </div>
                                                   </div>
                                               </form>
                                           </div>
                                 </div>
                            
                             </div>
                           
                          
      </Modal>
            // <div class="modal" id="playvideo" tabindex="-1" role="dialog"  aria-hidden="true" style={{display:'block',background:'rgba(0,0,0,0.7)'}}>
                        //  <div class="modal-dialog modal-dialog-centered" role="document">
                        //    <div class="modal-content brochure-modal-content">
                        //      <div class="modal-header brochure-modal-header">
                        //        <h5 class="modal-title brochure-modal-title" id="exampleModalLongTitle">Download Brochure</h5>
                        //        <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={this.props.HideBrochureModal}>
                        //          <span aria-hidden="true">&times;</span>
                        //        </button>
                        //      </div>
                        //      <div class="modal-body brochure-modal-body">
                        //          <div className='row'>
                        //          <div className="col-sm-12 col-md-12 col-lg-12">
                        //                        <form style={{ marginTop: -38 }}>
                        //                            <div class="form-row align-items-center">
                        //                                <div class="col-sm-12 col-md-12 col-lg-12">
                        //                                    <label class="sr-only" for="txtBrochureUserName" className='form-label-contact'> Name </label>
                        //                                    <input type="text" class="form-control j_forminputf" id="txtBrochureUserName" name="txtBrochureUserName" onChange={this.props.HandleUserInputsBrochure} placeholder="Enter name" value={this.props.mystate.txtBrochureUserName} />
                        //                                </div>
                       
                        //                                <div class="col-sm-12 col-md-12 col-lg-12">
                        //                                    <label class="sr-only" for="txtBrochureUserEmail"  className='form-label-contact'>Email ID</label>
                        //                                    <input sty type="email" class="form-control j_forminputf " id="txtBrochureUserEmail" name="txtBrochureUserEmail" onChange={this.props.HandleUserInputsBrochure} placeholder="Enter email ID" value={this.props.mystate.txtBrochureUserEmail} />
                        //                                </div>
                       
                        //                                <div class="col-sm-12 col-md-12 col-lg-12">
                        //                                    <label class="sr-only" for="txtBrochureMobile"  className='form-label-contact'>Mobile no</label>
                        //                                    <div class="input-group">
                        //                                        <div class="input-group-prepend">
                        //                                            <div class="input-group-text bg-light" style={{ borderRadius: 0 }}>+91</div>
                        //                                        </div>
                        //                                        <input type="text" class="form-control j_forminputf  " maxLength="10" id="txtBrochureMobile" name="txtBrochureMobile" onChange={this.props.HandleUserInputsBrochure} value={this.props.txtBrochureMobile} placeholder="Enter mobile number" />
                        //                                    </div>
                        //                                </div>
                        //                                {this.props.validationBrochureFormMessage!==''?
                        //             <div className="col-sm-12 col-md-12 col-lg-12">
                        //                 <div className="errorMessage">{this.props.validationBrochureFormMessage}</div>
                        //             </div>:null}
                                                      
                                                       
                        //                                <div class="col-sm-12 col-md-12 col-lg-12" style={{marginTop:'24px'}}>
                        //                                    <button type="button" className="j_btn" style={{backgroundColor:this.props.theme_color}}  onClick={()=>this.props.SendBrochureMail()}>Send Now</button>
                                                           
                        //                                </div>
                        //                            </div>
                        //                        </form>
                        //                    </div>
                        //          </div>
                            
                        //      </div>
                           
                        //    </div>
                        //  </div>
            //            </div>
        )
    }
}